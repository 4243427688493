@font-face {
  font-family: "Geomanist";
  src: url("./assets/fonts/Geomanist-Regular.otf") format("embedded-opentype"), /* Internet Explorer */ 
    url("./assets/fonts/Geomanist-Regular.otf") format("woff2"), /* Super Modern Browsers */ 
    url("./assets/fonts/Geomanist-Regular.otf") format("woff"), /* Pretty Modern Browsers */ 
    url("./assets/fonts/Geomanist-Regular.otf") format("truetype"), /* Safari, Android, iOS */ 
    url("./assets/fonts/Geomanist-Regular.otf") format("svg");
}

@font-face {
  font-family: "Geomanist Light";
  src: url("./assets/fonts/Geomanist-Light.otf") format("embedded-opentype"), /* Internet Explorer */ 
    url("./assets/fonts/Geomanist-Light.otf") format("woff2"), /* Super Modern Browsers */ 
    url("./assets/fonts/Geomanist-Light.otf") format("woff"), /* Pretty Modern Browsers */ 
    url("./assets/fonts/Geomanist-Light.otf") format("truetype"), /* Safari, Android, iOS */ 
    url("./assets/fonts/Geomanist-Light.otf") format("svg");
}

@font-face {
  font-family: "Geomanist Book";
  src: url("./assets/fonts/Geomanist-Book.otf") format("embedded-opentype"), /* Internet Explorer */ 
    url("./assets/fonts/Geomanist-Book.otf") format("woff2"), /* Super Modern Browsers */ 
    url("./assets/fonts/Geomanist-Book.otf") format("woff"), /* Pretty Modern Browsers */ 
    url("./assets/fonts/Geomanist-Book.otf") format("truetype"), /* Safari, Android, iOS */ 
    url("./assets/fonts/Geomanist-Book.otf") format("svg");
}

@font-face {
  font-family: "Geomanist Medium";
  src: url("./assets/fonts/Geomanist-Medium.otf") format("embedded-opentype"), /* Internet Explorer */ 
    url("./assets/fonts/Geomanist-Medium.otf") format("woff2"), /* Super Modern Browsers */ 
    url("./assets/fonts/Geomanist-Medium.otf") format("woff"), /* Pretty Modern Browsers */ 
    url("./assets/fonts/Geomanist-Medium.otf") format("truetype"), /* Safari, Android, iOS */ 
    url("./assets/fonts/Geomanist-Medium.otf") format("svg");
}

@font-face {
  font-family: "Geomanist Black";
  src: url("./assets/fonts/Geomanist-Black.otf") format("embedded-opentype"), /* Internet Explorer */ 
    url("./assets/fonts/Geomanist-Black.otf") format("woff2"), /* Super Modern Browsers */ 
    url("./assets/fonts/Geomanist-Black.otf") format("woff"), /* Pretty Modern Browsers */ 
    url("./assets/fonts/Geomanist-Black.otf") format("truetype"), /* Safari, Android, iOS */ 
    url("./assets/fonts/Geomanist-Black.otf") format("svg");
}

@font-face {
  font-family: "Geomanist Bold";
  src: url("./assets/fonts/Geomanist-Bold.otf") format("embedded-opentype"), /* Internet Explorer */ 
    url("./assets/fonts/Geomanist-Bold.otf") format("woff2"), /* Super Modern Browsers */ 
    url("./assets/fonts/Geomanist-Bold.otf") format("woff"), /* Pretty Modern Browsers */ 
    url("./assets/fonts/Geomanist-Bold.otf") format("truetype"), /* Safari, Android, iOS */ 
    url("./assets/fonts/Geomanist-Bold.otf") format("svg");
}

body {
  margin: 0;
  font-family: "Geomanist Book", "Geomanist", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Geomanist Book", "Geomanist", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
