.Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editButton {
  background-color: #f3eceb;
  color: #c7a2a0;
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Content span {
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #c7a2a0;
}

.ButtonsContainer {
  height: 50px;
  margin: 30px 59px;
  font-size: 16px;
  line-height: 16px;
}

.ButtonsContainer > button {
  height: 100%;
}

.ButtonsContainer p {
  padding: 4px;
  text-transform: none;
}

.inputsContainer {
  width: 300px;
  margin: auto;
  padding: 0 40px;
}
