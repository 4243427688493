.secondaryButton {
  background: linear-gradient(168.77deg, #F69579 4.37%, #F47572 101.74%);
  color: #fff!important;
}

.child {
  flex-grow: 1;
  justify-content: center;
}

.icon {
  margin-right: 10px;
}