.userButton {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 20px;
}

.userButton > span {
  font-family: "Geomanist book";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #c7a2a0;
}

.selected {
  background: rgba(237, 20, 91, 0.07);
  mix-blend-mode: normal;
}

.selected > span {
  color: #ed145b;
  display: none;
}

@media (min-width: 1000px) {
  .userButton {
    flex-direction: row;
    justify-content: start;
  }

  .userButton > span {
    font-size: 18px;
    line-height: 18px;
    margin: 20px;
  }
  .selected > span {
    display: inline;
  }
}
