.noTimeSlots {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px;
}

.noTimeSlots > h2 {
  font-family: Geomanist;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: #c7a2a0;
}

.noTimeSlots > span {
  text-align: center;
}

@media (max-width: 1000px) {
  .noTimeSlots {
    margin: 57px;
  }
}
