.Footer {
    margin-top: 84px;
    width: 100%;
    display: flex;
    align-self: flex-end;
    flex-direction: column;
  }
  
  .vtpLogo {
    max-width: fit-content;
    margin: 43px auto 41px 179px;
  }

  
@media (max-width: 1000px) {
  .Footer {
    display: none;
  }
}
