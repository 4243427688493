
.Card {
  margin-top: 16px;
  padding: 16px;
  position: relative;
  // border: 1px solid black;
}

.Date {
  padding-bottom: 5px;
  font-family: "Geomanist book";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #c7a2a0;
}

h3 {
  font-family: "Geomanist Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #2C0915;
}

.editIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: #C7A2A0;
}

.text {
  white-space: pre-line;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  border: 1px outset #c7a2a0;
  border-radius: 10px;
}
