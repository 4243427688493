.SignInForm {
  width: 300px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.mobileButton,
.desktopButton {
  text-decoration: none;
}

.mobileButton {
  display: none;
}

.desktopButton {
  max-width: 300px;
  display: block;
  margin: auto;
  margin-top: -10px;
  margin-bottom: 20px;
}

.pageTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 16px;
}

.ForgotPasword {
  width: 300px;
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: column;
}

.ForgotPasword > div {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  color: #c7a2a0;
}

.resetPasswordLink {
  font-size: 16px;
  line-height: 16px;

  text-align: center;
  text-decoration-line: underline;

  color: #760f2e;
}

.signUpButton {
  margin: 0 12px;
}

@media (max-width: 1000px) {
  .mobileButton {
    display: block;
  }
  .desktopButton {
    display: none;
  }
}
