.UnauthorisedLayout {
  display: flex;
  flex-direction: column;
  padding: 80px 0 0 165px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  min-height: calc(100vh - 192px);
}

.desktopHeader {
  display: flex;
  justify-content: space-between;
  padding-right: 100px;
}

.desktopLanguageSelector {
  width: 150px;
  max-height: 40px;
  margin-right: 100px;
}

.mobileLanguageSelector {
  display: none;
}

.desktopImg {
  height: 109px;
}

.mobileImg {
  max-width: 100%;
  width: 100%;
}

.content {
  margin-top: 100px;
  display: flex;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  width: 35%;
  width: calc(50%-230px);
  max-width: 650px;
}

.leftContainer .mobileImageContainer {
  display: none;
}

.pageTitle h1,
.pageFullMobileTitle h1 {
  font-family: Geomanist;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  color: #2c0915;
  max-width: 500px;
  margin-bottom: 16px;
}

.rightContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 106px;
  right: 165px;
  max-width: 800px;
  width: 40%;
  height: 100%;
}

.rightContainer img {
  display: flex;
  max-width: 800px;
  width: 100%;
}

.flag {
  width: 24px;
  height: auto;
  padding: 0 5px;
}

@media (max-width: 1600px) {
  .leftContainer {
    width: 45%;
  }
  .UnauthorisedLayout {
    padding-left: 100px;
  }
  .rightContainer {
    right: 65px;
    top: 150px;
  }
}

@media (max-width: 1300px) {
  .pageTitle h1,
  .pageFullMobileTitle h1 {
    font-size: 50px;
  }
  .rightContainer {
    top: 200px;
  }
  .rightContainerChoose {
    display: none;
  }
}

@media (max-width: 1200px) {
  .leftContainer {
    width: 100%;
  }
  .rightContainer {
    top: auto;
  }
}

@media (max-width: 1000px) {
  .UnauthorisedLayout {
    padding: 0;
  }
  .content {
    margin-top: 32px;
  }
  .rightContainer {
    display: none;
  }

  .leftContainer {
    width: 100%;
  }

  .desktopHeader {
    display: none;
  }

  .mobileLanguageSelector {
    display: block;
    width: 150px;
    margin: 0 16px 16px auto;
  }

  .leftContainer .mobileImageContainer {
    display: flex;
  }
  .pageTitle h1,
  .pageFullMobileTitle h1 {
    margin: 16px 44px;
    font-family: Geomanist;
    font-size: 32px;
    line-height: 37px;
  }
  
  .pageTitle h1 {
    max-width: 145px;
  }

}
