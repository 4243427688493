.getStarted {
  display: flex;
}

.desktopTitle {
  text-align: start;
}

.text {
  font-size: 30px;
}

.buttonsContainer {
  margin: 50px 0;
  max-width: 300px;
}

.loginLink {
  font-size: 20px;
  margin: 10px 0;
  text-align: center;
}

.signInLink {
  font-size: 16px;
  line-height: 16px;
  text-align: start;
  text-decoration-line: underline;
  color: #760f2e;
}

.signUpButton {
  text-decoration: none;
}

.mobileTitle {
  display: none;
  height: 98px;
}

@media (max-width: 1000px) {
  .desktopTitle {
    display: none;
  }
  .text {
    display: none;
  }
  .mobileTitle {
    display: flex;
    margin: 2.2em auto 2.5em 2.7em;
  }
  .buttonsContainer {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .signUpButton {
    min-width: 50%;
  }
}