.currentText {
  font-family: "Geomanist Bold";
  font-weight: bold;
  font-size: 12px;
  margin: 14px 15px auto auto;
  color: #f68e78;
}

.currentIcon,
.currentIconDelete {
  margin: auto;
  margin-right: 0;
  padding: 33px 10px;
}

.currentIconDelete {
  color: #ed145b;
  padding-right: 16px;
  cursor: pointer;
}

.currentIconDelete:hover {
  color: #C7A2A0;
}

.selectCard {
  cursor: pointer;
}
