.Step1 {
  width: 300px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Step1 > div {
  width: 100%;
}

.LabelTypography {
  font-size: 12px!important;
  line-height: 18px!important;
  color: #c7a2a0;
  max-width: 220px;
  // text-align: center;
}

