.ChooseTimeCards {
  display: flex;
  flex-direction: column;
}

.Card {
  height: 80px;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 16px 38px rgba(242, 215, 211, 0.469132);
  border-radius: 10px;
  margin-bottom: 24px;
}

.Date {
  margin: 15px 0 0 26px;
  font-family: "Geomanist Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #2c0915;
}

.Time {
  font-family: "Geomanist Book";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #c7a2a0;
  margin: 0 0 15px 26px;
}
