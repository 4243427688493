.CancelAppointment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 549px;
  margin: auto;
  padding: 20px;
  margin-top: 200px;
}

.vtpImage {
  max-height: 100px;
  margin-bottom: 100px;
}
