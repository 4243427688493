.invitedText {
  margin: 0 16px 0 auto;
  display: flex;
  align-items: center;
  color: #f68e78;
  font-family: "Geomanist Bold";
}

.deleteIcon {
  cursor: pointer;
  margin-left: 20px;
}

.deleteIcon:hover {
  color: #c7a2a0;
}

.acceptedInvite {
  margin-left: auto;
  position: relative;
  height: 100%;
  min-height: 90px;
  width: 127px;
  
  background: linear-gradient(163.85deg, #F69579 4.37%, #F47572 101.74%);
  transform: skew(-10deg);
  margin-right: -20px;
  padding-right: 38px;
  border-left: 1px solid black;

  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: #FFF;
}

.acceptedInvite span {
  -webkit-transform: none;
  transform: none;
}
