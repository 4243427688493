.AuthPagesTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userData {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #F3ECEB;
  border-radius: 10px;
  height: 44px;
  max-width: 50%;
}

.userName {
  padding:10px;
  display: block;
}

.keyboardArrowDown {
    padding-left: 10px;
}

.newLine {
  flex-direction: column;
}

.newLine .userData {
  max-width: 90%;
  margin-bottom: 24px;
}

@media (max-width: 400px) {
  .userName {
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
  }
}
