.ChooseTime {
  box-sizing: content-box;
  width: 100%;
}
.MobileLogo {
  max-height: 74px;
  margin: 48px 0;
  display: block;
}

.Title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px;
}

.Content {
  margin: 24px;
}

.title h1 {
  max-width: 85%;
}

.user {
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #c7a2a0;
}

.noTimeSlots {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px;
}

.noTimeSlots > h2 {
  font-family: Geomanist;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: #c7a2a0;
}

.noTimeSlots > span {
  text-align: center;
}

@media (min-width: 1000px) {
  .Title,
  .Content {
    max-width: 549px;
    margin: 24px auto;
  }
  .ChooseTime {
    margin: 50px auto;
  }
  .MobileLogo {
    display: none;
  }
  .noTimeSlots {
    margin: 57px;
  }
}
