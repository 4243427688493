.noVisitors {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noVisitors h2 {
  color: grey;  
  font-size: 32px ;
}
