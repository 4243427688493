.AddTimeSlot {
  max-width: 540px;
  margin: auto;
}

h1 {
  margin-bottom: 30px;
}

.timePickersContainer {
  margin: 24px;
  display: flex;
  justify-content: space-around;
}

input.inputProps {
  font-size: 28px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2C0915;
  overflow: visible;
}

@media (max-width: 500px) {
  .inputProps {
    font-size: 24px!important;
  }
}

@media (max-width: 415px) {
  .inputProps {
    font-size: 20px!important;
  }
}
@media (max-width: 370px) {
  .inputProps {
    font-size: inherit!important;
  }
}
