.CustomCard {
  position: relative;
  display: flex;
  align-items: center;
  height: 90px;
  width: 100%;
  box-shadow: 0px 16px 38px rgba(242, 215, 211, 0.469132);
  border-radius: 10px;
  margin-bottom: 24px;
  padding-left: 5px;
  overflow: hidden;
}

.Selected {
  background: linear-gradient(175.15deg, rgba(246, 149, 121, 0.18) 0.08%, rgba(244, 117, 114, 0.18) 98.91%);
  mix-blend-mode: normal;
  border-radius: 1;
}

.hr {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(179.07deg, #f69579 4.37%, #f47572 101.74%);
}

.textareaContent {
  height: auto;
  padding: 10px;
}

.Title {
  margin-left: 21px;
  font-family: "Geomanist Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #2c0915;
}

.Content {
  font-family: "Geomanist Book";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  white-space: pre-line;
  color: #c7a2a0;
  margin-left: 21px;
}

.Clickable {
  cursor: pointer;
}

.highlight {
  background: linear-gradient(171.39deg, #f69579 4.37%, #f47572 101.74%);
}
