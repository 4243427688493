.Step2 {
  display: flex;
  justify-content: center;
  min-width: 650px;
  height: 100%;
  position: relative;
  z-index: 1;
}

.Option {
  width: 321px;
  height: 293px;
  cursor: pointer;
  margin: 50px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 16px 38px rgba(242, 215, 211, 0.469132);
  border-radius: 12px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  margin: 31px;
  margin-top: 0;
}

.textContainer h3 {
  font: "Geomanist Medium";
}

.textContainer span {
  text-align: center;
  font-size: 12px;
  color: #C7A2A0;
}

.or {
  display: none;
}

@media (max-width: 1000px) {
  .Step2 {
    flex-direction: column;
    align-items: center;
  }
  .or {
    display: block;
  }
}
