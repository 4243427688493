.Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Title > svg {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  color: #C7A2A0;
  background-color: #F3ECEB;
}

.SaveButton {
  margin-left: 10px;
}
