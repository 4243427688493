.AuthorisedLayout {
  display: flex;
  flex-direction: column;
}

.childrenPage {
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
}

.childrenPage h1 {
  font-family: Geomanist;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  color: #2c0915;
}

.desktopLanguageSelector {
  display: none;
}

.mobileLanguageSelector {
  display: block;
  margin: 0 20px 0 auto;
  width: 150px;
}

.menu {
  display: flex;
}

.flag {
  width: 24px;
  height: auto;
  padding: 0 5px;
}

@media (min-width: 1000px) {
  .childrenPage {
    margin-top: 86px;
    margin-left: 227px;
    max-width: 549px;
  }
  .AuthorisedLayout {
    display: flex;
    flex-direction: row;
  }
  .menu {
    flex-direction: column;
    max-width: 223px;
    height: 100%;
    margin-top: 78px;
  }

  .desktopLanguageSelector {
    display: block;
    padding: 20px;
    width: 100%;
    max-height: 40px;
  }
  .mobileLanguageSelector {
    display: none;
  }
}
