.CustomInputFieldContainer {
  width: 100%;
  margin: 8px 0;
}

.CustomInputFieldContainer fieldset {
  border-radius: 10px;
}

.CustomInputField,
.CustomTextAreaField {
  width: 80%;
  max-width: 500px;
}

.CustomInputField,
.CustomTextAreaField > label {
  padding-left: 10px;
}
