.PasswordReset {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
}

.text {
  font-size: 24px;
  margin-left: 20px;
  margin-bottom: 30px;
}

.buttonContainer {
  margin-left: 8px;
  display: flex;
}